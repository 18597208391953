*{

    padding: 0;
    margin: 0;
    box-sizing: border-box
    ;


}

section{
padding: 40px 15%;
}


.contactbig{
    background-color: #101010;

    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: grid;
    
    align-items: center;
    grid-gap: 6rem;
}
 

.contact-img img{
    max-width: 100%;
    width: 720px;
    height: auto;
    border-radius: 10px;
   
} 

.contact-form h1{
    font-size: 80px;
    color: #fff;
    margin-bottom: 20px;
}
span{
    color: #f9004d;
}
.contact-form p{
    color: #c6c9d8bf;
    letter-spacing: 1px;
    line-height: 26px;
    font-size: 1.1rem;
    margin-bottom: 3.8rem;
}

.contact-form form input,
form textarea{
    width: 100%;
    padding: 17px;
    border: none;
    outline: none;
    background-color: #191919;
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 0.7rem;
    border-radius: 10px;


}

.contact-form textarea{
    resize: none;
    height: 200px;
}


.contact-form  form .btn{
display: inline-block;

background-color: #f9004d;

font-size: 1.1rem;
letter-spacing: 1px;
text-transform: uppercase;

font-weight: 600;

border:2px solid transparent;
border-radius: 10px;
width: 220px;
transition: ease .20s;

cursor: pointer;
}

.contact-form form  .btn:hover{
    border: 2px solid #f9004d;
    background-color: transparent;
    transform: scale(1.1);

}

@media (max-width:1570px) {
    section{
        padding: 80px 3%;
        transition: .2s;
    }
    .contact-form h1{
        font-size: 60px;


    }

    .contact-form p{
        margin-bottom: 3rem;
    }
}

@media (max-width:1090px){
    .contactbig {
        grid-gap: 2rem;
        transition: .3s;

    }
}

@media (max-width:1000px){
    .container{
        width: 100%;
    }
    .contact{
        grid-template-columns: 1fr;
    }
    .contact form{
        order: 2;
    }
    .contact-img img{
        max-width: 100%;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
   .nantu-img{
    margin-top: 70px;
   }
  }